// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Slideshow */

.slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 100%;
  }
  
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
  }
  
  .slide {
    display: inline-block;
    background-size: 'cover';
    height: 651px;
    width: 100%;
  }
  
  /* Buttons */
  
  .slideshowDots {
    text-align: center;
    padding: 15px
  }
  
  .slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
  
    cursor: pointer;
    margin: 15px 7px 0px;
  
    background-color: #c4c4c4;
  }
  
  .slideshowDot.active {
    background-color: #C5A94B;
  }

@media (max-width: 768px) {

    .slide {
        background-size: contain;
        height: 400px
    }
}
  `, "",{"version":3,"sources":["webpack://./src/components/Slideshow.css"],"names":[],"mappings":"AAAA,cAAc;;AAEd;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,qBAAqB;IACrB,wBAAwB;IACxB,aAAa;IACb,WAAW;EACb;;EAEA,YAAY;;EAEZ;IACE,kBAAkB;IAClB;EACF;;EAEA;IACE,qBAAqB;IACrB,YAAY;IACZ,WAAW;IACX,kBAAkB;;IAElB,eAAe;IACf,oBAAoB;;IAEpB,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;AAEF;;IAEI;QACI,wBAAwB;QACxB;IACJ;AACJ","sourcesContent":["/* Slideshow */\n\n.slideshow {\n    margin: 0 auto;\n    overflow: hidden;\n    max-width: 100%;\n  }\n  \n  .slideshowSlider {\n    white-space: nowrap;\n    transition: ease 1000ms;\n  }\n  \n  .slide {\n    display: inline-block;\n    background-size: 'cover';\n    height: 651px;\n    width: 100%;\n  }\n  \n  /* Buttons */\n  \n  .slideshowDots {\n    text-align: center;\n    padding: 15px\n  }\n  \n  .slideshowDot {\n    display: inline-block;\n    height: 20px;\n    width: 20px;\n    border-radius: 50%;\n  \n    cursor: pointer;\n    margin: 15px 7px 0px;\n  \n    background-color: #c4c4c4;\n  }\n  \n  .slideshowDot.active {\n    background-color: #C5A94B;\n  }\n\n@media (max-width: 768px) {\n\n    .slide {\n        background-size: contain;\n        height: 400px\n    }\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
